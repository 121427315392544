import React from "react";
import Ethereum from "../assets/images/ethuu.png";
import Binance from '../assets/images/svg/binance.png'
import Stellite from "../assets/images/svg/stellite.svg";
import Tezos from "../assets/images/svg/tezos.svg";
import Flow from "../assets/images/svg/flow.svg";
import IO from "../assets/images/svg/io.svg";
import Author from "../assets/images/banner.png";
import { Link, useNavigate } from "react-router-dom";
import ImgAudVideo from "./../separate/ImgAudVideo";
import config from "./config/config";
import NoData from "./seperatemodals/nodata";
import { Token_MyList_Func } from "../actions/axioss/user.axios";
export default function Activity({ datas, Loadmore, List, filter, SetTokens, Tokens, userProfile, Loader }) {
  const push = useNavigate();
  { console.log('activityyyy', datas, List, userProfile) }
  // var [Sort,SetSort] = ({image:-1,name:-1,category:-1,blockchain:-1,quantity:-1,price:-1})
  const Explore = async (sort) => {
    // SetSort({...sort,...{[]:}})
    // var page = (Tokens[filter]?.page)
    // var SendDATA = {
    //     TabName: filter,
    //     limit: 12,
    //     CustomUrl: userProfile?.CustomUrl,
    //     NFTOwner: userProfile?.WalletAddress,
    //     page: page ?? 1,
    //     from: 'myItem',
    //     sort : {}
    // }
    // let Resp = await Token_MyList_Func(SendDATA)
    // if (Resp?.success == 'success' && Resp.data.length > 0) {
    //     SetTokens({
    //         ...Tokens, ...{
    //             [filter]: {
    //                 list: [...Tokens[filter].list, ...Resp.data],
    //                 loader: ( Resp.data.length ==0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
    //                 page: Tokens[filter].page
    //             }
    //         }
    //     })
    // }
  }
  return (
    <>
      <div className="inner-content inventory">
        <h3 class="profilename">{userProfile.DisplayName}</h3>
        <h4 className="title-dashboard" data-aos="fade-right">
          Activity
        </h4>
        {/* <div className="table-ranking top">
          <div className="title-ranking">
            <div className="col-rankingg">
              onClick={Explore('price')}
              <Link to="#">Image</Link>
            </div>
            <div className="col-rankingg">
              <Link to="#">Name</Link>
            </div>
            <div className="col-rankingg">
              <Link to="#">Category</Link>
            </div>
            <div className="col-rankingg blockchain">
              <Link to="#">Blockchain</Link>
            </div>
            <div className="col-rankingg">
              <Link to="#">Quantity</Link>
            </div>
            <div className="col-rankingg">
              <Link to="#">Price</Link>
            </div>

            <div className="col-rankingg">
              <Link to="#">Action</Link>
            </div>
          </div>
        </div> */}
        {/* <div className="table-ranking ">

            {console.log("hiawhdoiawd",datas?.list?.length,Loader)}
          {(datas?.list?.length > 0) && Loader && (datas?.list?.length == 12) && <div className="table-btn">
            <Link to="#" onClick={Loadmore}>View More</Link>
          </div>}
        </div> */}

        <div className="activity_table">
          <div className="acti_table_header aling_image_header_width">
            <div className="acti_table_singlehead"><p>Image</p></div>
            <div className="acti_table_singlehead"><p>Name</p></div>
            <div className="acti_table_singlehead"><p>Category</p></div>
            <div className="acti_table_singlehead"><p>Blockchain</p></div>
            <div className="acti_table_singlehead"><p>Quantity</p></div>
            <div className="acti_table_singlehead"><p>Price</p></div>
            <div className="acti_table_singlehead"><p>Action</p></div>
          </div>

          <div className="aling_image_body_width">
          {datas?.list?.length == 0 ? <>
           
           <NoData />
         </> : datas?.list?.length > 0 ?
           datas?.list?.map((item, key) => {
             return (

<>
<div className="acti_table_values">
               <div className="acti_table_singlevalue"><div
               className="image"
               onClick={() => {
                 push(
                   item.Activity == "Bid" ||
                     item.Activity == "Edit" ||
                     item.Activity == "Cancel"
                     ? ""
                     : `/info/${item.CollectionNetwork}/${item.ContractAddress}/${item.To}/${item.TokenId}`
                 );
               }}
             >
               <ImgAudVideo
                 file={item?.CompressedFile !== undefined ? item?.CompressedFile?.split(':')[0] == 'https' ? item?.CompressedFile : `${config.IMG_URL}/nft/${item?.Creator}/Compressed/NFT/${item?.CompressedFile}` : item?.CompressedFile?.split(':')[0] == 'https' ? item.CompressedFile : `${config.IMG_URL}/nft/${item?.Creator}/Original/NFT/${item.CompressedFile}`}
                 origFile={item?.OriginalFile?.split(':')[0] == 'https' ? item?.OriginalFile : `${config.IMG_URL}/nft/${item?.Creator}/Original/NFT/${item?.OriginalFile}`}
                 thumb={`${config.IMG_URL}/nft/${item.Creator}/Compressed/NFT_THUMB/${item?.CompressedThumbFile}`}
                 type={
                   item?.CompressedFile
                     ? item?.CompressedFile?.includes(".webp") || item?.CompressedFile?.includes(".png")
                       ? "image"
                       : item?.CompressedFile.includes(".webm")
                         ? "video"
                         : "audio"
                     : item?.CompressedFile
                 }
               />
               {/* <img src={data.authorImg} alt="Image" /> */}
             </div></div>
               <div className="acti_table_singlevalue"><p>{item?.TokenName?.length > 10 ? <>{item.TokenName.slice(0, 10)}...</> : item.TokenName}</p></div>
               <div className="acti_table_singlevalue"><p>{item.Category}</p></div>
               <div className="acti_table_singlevalue">
                 <div className="coin">
                   <img src={item.CollectionNetwork == "BNB" ? Binance : Ethereum} alt="Image" className="card_image mr-2" width={20} />
                   <p>{item.CollectionNetwork}</p>
                 </div>
               </div>
               <div className="acti_table_singlevalue"><p>{item.NFTQuantity}</p></div>
               <div className="acti_table_singlevalue"><p> {item.NFTPrice}
               {item.CoinName}</p></div>
               <div className="acti_table_singlevalue"> <div className="activity_act_button">
               
               <i className="fas fa-circle"></i>
               {item.Activity}
             </div></div>
             </div>
</>


              
             );
           }) :
           <><NoData /></>}
          </div>
          
        </div>
      </div>
    </>
  );
}
