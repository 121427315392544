

export const network =
{
    97 : {   // binance testnet
    
        chain:"Binance",
        rpcUrl:"https://endpoints.omniatech.io/v1/bsc/testnet/public",
        networkVersion:'97',
        chainId :'97',
        currencySymbol:"BNB",
        tokenSymbol:"CAKE",
        tokenAddress:'0x7CAB80ce0E55F46378E493B584eE61aD68878f11'.toLowerCase(),
        singleContract :'0x1d205138f36b4938dD7930Ea57add7bfD376B4D1'.toLowerCase(),
        multipleContract:'0x641e7A8F7e414eE6F05C8B0528877e680e4693E6'.toLowerCase(),
        tradeContract :"0x419Ee320F99287A93B4362C4A45679E30736d05a".toLowerCase(),
        adminAddress:"0x025c1667471685c323808647299e5dbf9d6adcc9".toLowerCase(),
        chainName : "BSC_TESTNET"

    },  
    // "80001": {  // polygontestnet

    //     chain:"Mumbai",
    //     rpcUrl:"https://matic-mumbai.chainstacklabs.com",
    //     networkVersion:'80001',
    //     chainId :'80001',
    //     currencySymbol:"MATIC",
    //     tokenSymbol:"CAKE",
    //     siteUrl:"https://mumbai.polygonscan.com",
    //     tokenAddress:"0xd29CA1888bAb7aB95A62A70761dfaAEEb192d874",
    //     singleContract :"0x702Acda89A18D0D1BD2c105bbC26f8730487A61a".toLowerCase(),
    //     multipleContract:"0xac79640345250eEdF71a9D6Bb9Fde756dDb6d677".toLowerCase(),
    //     tradeContract :("0x4856fdfeea5589aB7E56b06BD2424A5C3C4A202B").toLowerCase(),
    //     adminAddrsss:"0x025c1667471685c323808647299e5dbf9d6adcc9".toLowerCase(),
    // },
    43113 : {  // Avalanche_Testnet 

        chain:"AVAX",
        rpcUrl:"https://api.avax-test.network/ext/bc/C/rpc",
        networkVersion:'43113',
        chainId :'43113',
        currencySymbol:"ETH",
        tokenSymbol:"CAKE",
        siteUrl:"https://testnet.snowtrace.io",
        tokenAddress:"0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A".toLowerCase(),
        deadaddress : '0x000000000000000000000000000000000000dEaD'.toLowerCase(),
        tradeContract   :   '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase(),
        singleContract      :  '0x9A9EBc3A48D9ddc54A2D6A3599642aBA4363E7e1'.toLowerCase(),
        multipleContract  :   '0x4958A36d8d71abd35D5434EF78023B3284D93e63'.toLowerCase(),
        adminAddrsss:"0x025c1667471685c323808647299e5dbf9d6adcc9".toLowerCase(),
        chainName : "FUJI"

    }

}