import React from 'react'

function EmptyReferer() {
  return (
    <>
    <div className="text-centre">
        <h3>Refer new users to earn</h3>
        {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
        {/* <p>Nothing for ReSale at this time</p> */}
                <div className="load-more">
                {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                </div>
        </div></>
  )
}

export default EmptyReferer;