import React, { useEffect } from "react";
import authorImg from "../assets/images/banner.png";
import Binance from '../assets/images/svg/binance.png'
import productImg from "../assets/images/product52.jpg";
import ethImg from "../assets/images/ethuu.png";
import Countdown from "react-countdown";
import { NavLink, useNavigate, Link } from "react-router-dom";
import ImgAudVideo from "../separate/ImgAudVideo";
import config from "./config/config";
import { address_showing } from "../actions/common";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LikeRef } from "./seperatemodals/LikeRef";

import timer from "../assets/images/timer.png";

import author from '../assets/images/avatar.png';
import endless from '../assets/images/endless.png';

export default function Card({
  product,
  index,
  LikeList,
  LikedTokenList,
  setLikedTokenList,
  LikeForwardRef,
  from,
}) {
  console.log("likrreeeecardd", from, product);
  const { accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );

  useEffect(() => {
    const preventContextMenu = (event) => {
        event.preventDefault();
    };

    const images = document.querySelectorAll('img');

    images.forEach((image) => {
        image.addEventListener('contextmenu', preventContextMenu);
    });

    return () => {
        images.forEach((image) => {
            image.removeEventListener('contextmenu', preventContextMenu);
        });
    };
}, []);

  const LikeAction = async () => {
    // console.log("ksgfsdga", LikeForwardRef, accountAddress, product);
    if (accountAddress) {
      var check = await LikeForwardRef.current.hitLike(product);
      toast.success("you " + check + "d token", { autoClose: 500, closeButton: true, closeOnClick: true });
    } else toast.error("Connect Wallet", { autoClose: 1000, closeButton: true, closeOnClick: true });
  };

  let renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Auction Completed!</span>;
    } else {
      return (
        <span>
          {" "}
          <span className="hourds">{formatTime(days)} d</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(hours)} h</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(minutes)} m</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(seconds)} s</span>{" "}
        </span>
      );
    }
  };

  let formatTime = (time) => {
    return String(time).padStart(2, "0");
  };
  const push = useNavigate();
  return (
    <div data-aos="zoom-in-up">
      {index === 0 && (
        <LikeRef
          ref={LikeForwardRef}
          setLikedTokenList={setLikedTokenList}
          LikeList={LikeList}
        />
      )}
      {/* {product.map((data,index) => { return( */}

      <div className="sc-product style1">
        <div className="top">
          {/* <Link
              to={ product.from !== "othercollection" ? `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : accountAddress === product?.NFTCreator ? `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : "#"
            }
            state={{data: (from == "usercollection" ? product : null)}}
              className="tag"
            >
              {product?.NFTName?.length>13 ? product?.NFTName?.substring(0,10) + "..." : product?.NFTName}
            </Link> */}
          {/* {console.log('adgvhkdgsk',product?.NFTName?.length)} */}
          {from !== "usercollection" &&
            (LikedTokenList?.some(
              (value) => value.NFTId === product.NFTId
            ) ? (
              <div className="wish-list active">
                <Link
                  to="#"
                  className="heart-icon"
                  onClick={() => LikeAction()}
                ></Link>
              </div>
            ) : (
              <div className="wish-list">
                <Link
                  to="#"
                  className="heart-icon"
                  onClick={() => LikeAction()}
                ></Link>
              </div>
            ))}
        </div>
        <div className="features">
          <div
            className="product-media  audImgVid"
            onClick={() => {
              !product?.CompressedFile?.includes(".mp3") &&
                push(
                  `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}`, { state: product}
                );
            }}
          >
            {/* <NavLink to="/details"> */}

            {/* {console.log(
                "tokenntypeee",
                product?.CompressedFile,
                product,
                product?.CompressedFile?.includes(".mp3")
              )} */}
            {from === "usercollection" ? (
              <iframe style={{
                background: 'url(' + product?.Image + ')', backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%", maxHeight: 288, minHeight: 288, minWidth: 288, maxWidth: 288, borderRadius: 15
              }} height="200" width="300" title="Iframe Example" id="myiFrame"></iframe>
              // <object data={product?.Image} width="288" height="288"></object>
              //  <iframe style={{ background: 'url(' + product?.Image + ')',backgroundRepeat: "no-repeat",
              //  backgroundSize: "100% 100%",maxHeight: 288,minHeight: 288,minWidth: 288,maxWidth: 288,borderRadius:15}} height="200" width="300" title="Iframe Example" id="myiFrame"></iframe> 
              // <img src={product?.Image} className="img-fluid" />
            ) : (
              <ImgAudVideo
                file={product?.CompressedFile.split(':')[0] == 'https' ? product?.CompressedFile : `${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`}
                origFile={product?.NFTOrginalImage.split(':')[0] == 'https' ? product?.NFTOrginalImage : `${config.IMG_URL}/nft/${product?.NFTCreator}/Original/NFT/${product?.NFTOrginalImage}`}
                thumb={`${config.IMG_URL}/nft/${product.NFTCreator}/Compressed/NFT_THUMB/${product?.CompressedThumbFile}`}
                type={
                  product?.CompressedFile
                    ? product?.CompressedFile?.includes(".webp") || product?.CompressedFile?.includes(".png")
                      ? "image"
                      : product?.CompressedFile.includes(".webm")
                        ? "video"
                        : "audio"
                    : product?.CompressedFile
                }
              />
            )}

            {/* <img src={product.CompressedFile} alt="images" /> */}
            {/* </NavLink> */}
          </div>
          {/* {console.log('timeeeee',product)} */}
          {product.PutOnSaleType === "TimedAuction" &&
            new Date(product.EndClockTime).getTime() >= Date.now() && (
              <div className="featured-countdown">
                {/* <Countdown
                      date={product.EndClockTime}
                      // autoStart={true}
                      renderer={renderer}
                    /> */}

                {new Date(product?.ClockTime) > Date.now() ? (
                  <span className="js-countdown">Auction Starts in {Math.ceil((new Date(product.ClockTime).getTime() - new Date(Date.now()).getTime()) / (1000 * 3600 * 24))} Days</span>
                )
                  :
                  ((new Date(product?.EndClockTime) > Date.now()) ?
                    <span className="js-countdown">
                      <img className="img-fluid timer" src={timer} alt="ff" />
                      {new Date(
                        product?.EndClockTime
                      ).getTime() >= Date.now() && (
                          <Countdown
                            date={product?.EndClockTime}
                            // autoStart={true}
                            renderer={renderer}
                          />
                        )}
                    </span>
                    :
                    <span className="js-countdown">Auction Ended</span>
                  )}
              </div>
            )}

          {/* <div className="rain-drop1"><img src={require("../assets/images/icon/rain1.svg")} alt="images" /></div>
                                    <div className="rain-drop2"><img src={require("../assets/images/icon/rain2.svg")} alt="images" /></div> */}
        </div>
        {console.log("productttt", product)}
        <div className="bottom pads">
          <div>
            {/* <Link
              to={ product.from !== "othercollection" ? `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : accountAddress === product?.NFTCreator ? `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : "#"
            }
            state={{data: (from == "usercollection" ? product : null)}}
              className="tag"
            > */}
            <Link
              to={product.from !== "othercollection" ? `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : accountAddress === product?.NFTCreator ? `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : "#"
              }>
              {product?.NFTName?.length > 13 ? product?.NFTName?.substring(0, 10) + "..." : product?.NFTName}
            </Link>
          </div>
          <div className="details-product">
            <div className="author">

              <div className="avatar">
                <img src={product?.cur_owner_Profile ? `${config.IMG_URL}/user/${product.NFTOwner}/profile/${product.cur_owner_Profile}` : config.profile} alt="images" />
              </div>

              <div className="content">
                <div className="position">
                  <Link to={"/profile" + "/" + product.cur_owner_CustomUrl}>
                  {/* {from === "usercollection" ? product.from === "othercollection" ? "Creator" : "Owner" : "Creator"} */}
                  {from === "usercollection"
                    ? address_showing(
                      product.OwnerName
                        ? product.OwnerName
                        : product.NFTOwner
                    )
                    : product &&
                    (product.cur_owner_DisplayName
                      ? product.cur_owner_DisplayName
                      : product?.NFTCreator?.substring(0, 5) +
                      "..." +
                      product?.NFTCreator?.substring(38, 42))}
                      </Link>
                </div>
                {/* <div className="position name">

                  <Link to="/">
                    {from === "usercollection"
                      ? address_showing(
                        product.OwnerName
                          ? product.OwnerName
                          : product.NFTOwner
                      )
                      : product &&
                      (product.Creator_DisplayName
                        ? product.Creator_DisplayName
                        : product?.NFTCreator?.substring(0, 5) +
                        "..." +
                        product?.NFTCreator?.substring(38, 42))}
                  </Link>
                </div> */}
              </div>
            </div>

            {/* <div className="current-bid">

                
                <div className="subtitle">
                 Auction
                </div>
                <div className="price text-center">
                  <img src={require("../assets/images/endless.png")} style={{width:25,height:25,marginTop:5}} />
                </div>
              </div> */}
          </div>

          <div className="d-flex justify-content-between">
            {(product.PutOnSaleType == "FixedPrice" || product.PutOnSaleType == "TimedAuction" || product?.highbidamount) ?
              <div className="current-bid">

                <div className="subtitle subss">
                  {product.PutOnSaleType == "FixedPrice"
                    ? "Price"
                    : product.PutOnSaleType == "TimedAuction"
                      ? "Current Bid"
                      : product.PutOnSale == "false" &&
                      product?.highbidamount &&
                      "High bid"}

                </div>
                <div className="name">

                  {/* <Link className="numbers" to={`/profile/${product?.Creator_CustomUrl}`}> */}
                  {/* <Link className="numbers" to="/">
                    {from === "usercollection"
                      ? address_showing(
                        product.OwnerName
                          ? product.OwnerName
                          : product.NFTOwner
                      )
                      : product &&
                      (product.Creator_DisplayName
                        ? product.Creator_DisplayName
                        : product?.NFTCreator?.substring(0, 5) +
                        "..." +
                        product?.NFTCreator?.substring(38, 42))}
                  </Link> */}
                </div>
                <div className="price">
                  <span className="cash cashsize" >
                    {((product?.NFTPrice !== "" && product?.NFTPrice !== "0" && product?.NFTPrice !== 0) || product?.highbid) && (
                      <span className="icon" style={{ marginLeft: "4px" }}>
                        <img src={product?.CollectionNetwork == "BNB" ? Binance : ethImg} alt="images" width={20} className="card_image" />
                      </span>
                    )}

                    {product.PutOnSaleType == "FixedPrice" ? (
                      `${product?.NFTPrice}  ${product?.CoinName.slice(0, 11)}`
                    ) : product.highbidamount ? (
                      `${product?.highbidamount} ${product?.highbidcoin.slice(0, 11)}`
                    ) : product.PutOnSaleType == "TimedAuction" ? (
                      `${product.NFTPrice} ${product?.CoinName.slice(0, 11)}`
                    ) : (
                      <></>
                    )}
                    {/* 1.63 ETH */}
                  </span>

                </div>
              </div>
              :
              <div className="current-bid">

                <div className="subtitle">
                  Auction
                </div>
                <div className="price text-center">
                  <img src={endless} className="endless" />
                </div>
              </div>}

            <div className="product-button">
              {console.log("product",product)}
              <Link


// push(
//   `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}`, { state: product}
// );
                to={`/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}`}
                state={{ state: product }}
                data-toggle="modal"
                data-target="#popup_bid"
                className="tf-button"
              >
                {/* <Link to="/"  className="tf-button"> */}
                {" "}
                <span className="icon-btn-product"></span>
                {/* {console.log("tokennnnnn",product,accountAddress)} */}
                {product?.NFTOwner == accountAddress
                  ? "Owned"
                  : product?.PutOnSaleType == "FixedPrice"
                    ? "Buy Now"
                    : "Place Bid"}
              </Link>
            </div>

          </div>

        </div>

      </div>

    </div>
  );
}
