import React, { useState } from "react";
import Wallet1 from "../assets/images/svg/icon-wallet-1.svg";
import Wallet2 from "../assets/images/svg/icon-wallet-2.svg";
import Wallet3 from "../assets/images/walletconnect.png";
import Wallet4 from "../assets/images/TWT.png";

import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { NavLink } from "react-router-dom";

import { Account_Section } from '../redux/constants'

import { connectWallet } from './hooks/useWallet';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isEmpty } from '../actions/common';
import { useNavigate, useParams } from "react-router-dom";

import { userRegister, GetUserCookieToken } from '../actions/axioss/user.axios'
import { GetNftCookieToken } from "../actions/axioss/nft.axios";
import { useWeb3React } from '@web3-react/core';
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import EditProfile from "./EditProfile";

// import { WalletConnectConnector } from "@web3-react/walletlink-connector";
// import { WalletLinkConnector,WalletConnectConnector } from "@web3-react/walletlink-connector";
import { Web3ReactProvider } from '@web3-react/core'
import Config from "./config/config"
import { Web3Provider } from "@ethersproject/providers";
import Web3 from "web3";
import BeforeloginEditProfile from "./BeforeloginEditProfile";

export default function ConnectWallet() {
    const [wallet, setWallet] = React.useState([
        { icon: Wallet1, beta: "Alpha", title: "Meta Mask", description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters", id: "MetaMask" },
        { icon: Wallet3, beta: "Alpha", title: "Wallet Connect", description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters", id: "WalletConnect" },
        { icon: Wallet4, beta: "Alpha", title: "Trust Wallet", description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters", id: "TrustWallet" },
    ])
    const [mobileWallet, setMobileWallet] = React.useState([
        { icon: Wallet1, beta: "Alpha", title: "Meta Mask", description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters", id: "MetaMask" },
        { icon: Wallet3, beta: "Alpha", title: "Wallet Connect", description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters", id: "WalletConnect" },
    ])

    const { referralLink } = useParams();
    const [updatePage, setUpdatePage] = useState(true)
    const [dataa, setDataa] = useState({})
    const [accDetail,setAccDetail]=useState({})

    const dispatch = useDispatch();
    var navigate = useNavigate();
    const { token } = useSelector(state => state.LoginReducer.User)
    const { activate, deactivate, active, chainId, account } = useWeb3React();

    const initialConnectWallet = async (type,title) => {
console.log("vvv",window.ethereum,window.web3)
        const id=toast.loading( type+"Connecting")
            var accountDetails = await connectWallet(type)
            console.log("11111",accountDetails,type,window.ethereum)
            if (!isEmpty(accountDetails)) {
                var resp = await setWalletAddress('InitialConnect', accountDetails?.accountAddress, type,id)
                // console.log("resp",resp)
                setAccDetail(accountDetails)
                if (resp?.success == 'success') {
                    toast.update(id, { render: resp.msg, type: resp.success, autoClose: 1000, isLoading: false, closeButton: true, closeOnClick: true })
                    dispatch({
                        type: "Account_Section",
                        Account_Section: { AccountDetails: accountDetails }
                    })
                    navigate("/")
                }
              

            }
            else toast.update(id, { render: "Try Again", type: 'error', autoClose: 1000, isLoading: false, closeButton: true, closeOnClick: true })
    }
    const setWalletAddress = async (type, walletAddress, walletType,id) => {
        if (walletAddress) {
            var NewMethod = {
                Type: type,
                WalletAddress: walletAddress,
                WalletType: walletType,
            };
            let Resp = await userRegister(NewMethod);
            console.log('inittttt', Resp)
            if (Resp?.already && Resp?.data?.DisplayName != "") {
                if (Resp?.success == 'success') {
                    dispatch({
                        type: 'Register_Section',
                        Register_Section: {
                            User: {
                                payload: Resp.data,
                                token: Resp.token ? Resp.token : token
                            }
                        }
                    })
                    document.cookie = 'token' + "=" + Resp?.token + ";" + ";path=/";
                    GetNftCookieToken();
                    GetUserCookieToken();
                    return Resp
                }
                else return Resp
            } else {
                toast.update(id, { render: "Create Profile First", type: 'success', autoClose: 1000, isLoading: false, closeButton: true, closeOnClick: true })
                setDataa(Resp?.data)
                setUpdatePage(false)
            }
        }
        else return { success: 'error', msg: 'No Address Detected.. Check Your Wallet' }

    }




    return (
        <>
            <Header />
            {updatePage ? <div className="wallets">
                <section className="tf-page-title" data-aos="fade-left">
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="breadcrumbs">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li>Connect Wallet</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="tf-connect-wallet" >
                    <div className="tf-container">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="tf-heading style-5">
                                    <h4 className="heading" data-aos="fade-up">Connect Your Wallet</h4>
                                    <p className="sub-heading" data-aos="fade-down">Choose how you want to connect. There are several wallet providers.</p>
                                </div>
                            </div>

                            {
                       
                            wallet.map((data, index) => {
                                return (
                                    <div className="col-lg-4 col-md-6" data-aos="zoom-in">
                                        <div className="tf-wallet" onClick={() => initialConnectWallet(  window.ethereum ?  data.id : "WalletConnect" )}>
                                            <div className="icon">
                                                <img src={data.icon} alt="Image" width="100" height="100" classNameName="img-fluid" />
                                                <span><h6 className="title">{data.title}</h6></span>
                                            </div>
                                            <p className="content">{data.description}</p>
                                        </div>
                                    </div>

                                )
                            })
                            }
                        </div>
                    </div>
                </section>
            </div> :
                <>
                    <section className="tf-page-title" data-aos="fade-left">
                        <div className="tf-container">
                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="breadcrumbs">
                                        <li><NavLink to="/">Home</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <BeforeloginEditProfile valData={dataa} accountDetails={accDetail}/>
                </>}

            {/* </Web3ReactProvider> */}
            <Footer />
        </>
    )
}