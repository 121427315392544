import React, { useState, useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import config from './config/config'
import faqimg from "../assets/images/galaxy-7040416__480.webp";


export default function Blogdetail() {
    const location = useLocation();
    console.log("DDDDDD", location, location?.state, "location")
    const [blog, setBlog] = useState({})

    useEffect(() => {
        setBlog(location?.state?.item)
    }, [])



    return (
        <>

            <Header />

            <div className="blog blogdetail">
                <section className="tf-page-title style-2" data-aos="fade-right">
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="breadcrumbs">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li>Blogdetail</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </section>

                <section className="tf-faq">
                    <div className="tf-container">
                        <div className="blogd">
                            <h5 className="mb-2" data-aos="fade-down">{blog?.heading}</h5>
                            <p className="grays mb-4">Published By {blog?.date && `${new Date(blog?.date).toLocaleString('default', { month: 'long' })} ${new Date(blog?.date).getDate()},${new Date(blog?.date).getFullYear()}`}</p>
                            <div className="text-centers">
                                <img class="blogdetailimg mt-3" src={`${config.IMG_URL}/${blog?.img}`} alt="Card image cap" />
                            </div>
                            <p className="pt-4" data-aos="fade-down"><div
                                dangerouslySetInnerHTML={{ __html: blog?.content }}
                            />
                            </p>
                        </div>

                    </div>
                </section >
            </div>

            <Footer />
        </>
    )
}







