import React, { useEffect, useState } from "react";
import Ethereum from "../assets/images/ethuu.png";
import Binance from '../assets/images/svg/binance.png'
import Stellite from "../assets/images/svg/stellite.svg";
import Tezos from "../assets/images/svg/tezos.svg";
import Flow from "../assets/images/svg/flow.svg";
import IO from "../assets/images/svg/io.svg";
import Author from "../assets/images/banner.png";
import { Link, useNavigate } from "react-router-dom";
import ImgAudVideo from "../separate/ImgAudVideo";
import config from "./config/config";
import NoData from "./seperatemodals/nodata";
import { getRefferedUsersList } from "../actions/axioss/user.axios";
import pf_img from '../assets/images/activity.png';
import { address_showing, copydata } from "../actions/common";
import action_config from './config/config';
import { toast } from 'react-toastify';
import CopyToClipboard from "react-copy-to-clipboard";
import users from '../assets/images/user.png';
import moment from "moment";
import EmptyReferer from "./seperatemodals/EmptyReferer";

import { useSelector } from "react-redux";
export default function Referral({ datas, Loadmore, List, filter, SetTokens, Tokens, userProfile, Loader }) {
    const push = useNavigate();
    { console.log('activityyyy', datas, List, userProfile) }

    const [referrallist, setReferrallist] = useState([])
    const [totalEarn, setTotalEarn] = useState(0)

    useEffect(() => {
        getReferralUserfun()
    }, [userProfile])

    const getReferralUserfun = async () => {
        console.log('sefsef');
        const Resp = await getRefferedUsersList({ WalletAddress: userProfile.WalletAddress })
        console.log("RespResp", Resp);
        setReferrallist(Resp?.data)
    }



    return (
        <>
            <div className="inner-content inventory referral_pages">
                <h3 class="profilename">{userProfile.DisplayName}</h3>


                <h4 className="title-dashboard" data-aos="fade-right">
                    Referral
                </h4>
                <div className="top_sec">
                    <div>
                        <Link to="/referraldetail">Learn More <i class="fa-solid fa-arrow-up-right-from-square"></i></Link>
                    </div>
                    {/* <div>
                        <button type="button" className=" copy_btn" onClick={() => { copydata(userProfile.referralCode); toast.success("Referral Code Copied", { autoClose: 1000 }) }} >Copy referral code</button>
                    </div> */}

                    <div>

                        <CopyToClipboard
                            text={`Site Link: ${config.FRONT_URL}/connect; Referrel Code: ${userProfile?.referralCode}`}
                            onCopy={() =>
                                toast.success("Referral Link Copied", { autoClose: 1000 })
                            } >
                            <button type="button" className=" copy_btn" >Copy referral Link</button>
                            {/* <i className="fas fa-sticky-note notes_fa cur_pointer"></i> */}
                        </CopyToClipboard>
                    </div>
                </div>

                <div className="table-ranking top">
                    <div className="title-ranking">
                        <div className="col-rankingg">
                            <Link to="#">S.No</Link>
                        </div>
                        <div className="col-rankingg">
                            <Link to="#">Profile</Link>
                        </div>
                        <div className="col-rankingg">
                            <Link to="#">Name</Link>
                        </div>
                        <div className="col-rankingg">
                            <Link to="#">Joined Date</Link>
                        </div>
                        <div className="col-rankingg blockchain">
                            <Link to="#">Wallet Address</Link>
                        </div>
                    </div>
                </div>
                <div className="table-ranking referral_tables">
                    {referrallist.length != 0 ? referrallist?.map((e, i) => (
                        <div className="content-ranking">
                            {console.log('serr', e)}
                            <div className="col-rankingg">
                                {i + 1}
                            </div>
                            <div className="col-rankingg">
                                {console.log("imagess", action_config.IMG_URL + "/user/" + e?.WalletAddress + '/profile/' + e?.Profile)}
                                {/* <img src={e.pf} alt="pf" className="img-fluid pf_img" /> */}
                                <img src={e?.Profile ? action_config.IMG_URL + "/user/" + e?.WalletAddress + '/profile/' + e?.Profile : config.profile} className="img-fluid pf_img" alt="images" />
                            </div>
                            <div className="col-rankingg">
                                {e.DisplayName}
                            </div>
                            <div className="col-rankingg">
                                {/* {moment(e.createdAt).format("YYYY-MM-DD h:mm:ss a")} */}
                                {new Date(e.createdAt).toLocaleString()}
                            </div>
                            <div className="col-rankingg">
                                {address_showing(e.WalletAddress)}
                            </div>


                        </div>
                    )) : <><EmptyReferer /></>}





                    {datas?.list?.length > 0 && Loader && <div className="table-btn">
                        <Link to="#" onClick={Loadmore}>View More</Link>
                    </div>}
                </div>
            </div>
        </>
    );
}
