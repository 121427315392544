import React, { useEffect, useState, useRef } from "react";
import Author from "../assets/images/product52.jpg";
import Card from "../views/Card.js";
import Tick from "../assets/images/svg/tick.svg";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import Countdown from 'react-countdown';
import Modals from "./Modals";
import { useSelector } from "react-redux";
import { CollectionByCreator, HotAuctionHotSales, Token_List_Func, TopCreatorApi } from "../actions/axioss/nft.axios"
import { getCmsContent, getarticle } from "../actions/axioss/cms.axios"
import { address_showing, axiosFunc } from "../actions/common";
import config from './config/config'
import ImgAudVideo from "../separate/ImgAudVideo";
import NoData from "./seperatemodals/nodata";
import { SearchAction } from "../actions/axioss/user.axios"
import { Getpromotedtoken } from "../actions/axioss/nft.axios";
import { data } from "jquery";
import { createImg } from "../actions/axioss/user.axios";

// import { midjourney } from "../actions/axioss/user.axios";
import axios from 'axios'
import faqimg from "../assets/images/galaxy-7040416__480.webp";
import rocket from '../assets/images/RocketLaunch.png';
import aiiimg from '../assets/images/aiimg.png';
import placee from '../assets/images/Imageplaceholder.png';
import wing1 from '../assets/images/pngwing 1.png';
import wing2 from '../assets/images/pngwing 2.png';

import author from '../assets/images/Artist1.png';

// import { Web3Auth } from "@web3auth/modal";

export default function Home() {
    const [show, setShow] = React.useState(false);
    const [hotactionstate, SetHotAuctionstate] = useState(true)
    const [hotsalestate, SetHotsalestate] = useState(true)
    const [bannerstate, setBannerstatus] = useState(true)
    const { payload, isAdmin } = useSelector(state => state.LoginReducer.User)
    const navigate = useNavigate()
    // const [ishome,setIshome] = React.useState(false);

    // const { state , pathname} = useLocation();
    // useEffect(()=>
    // {
    //     setIshome(state?true:false);
    //     if(ishome)
    //     {
    //         var ele = document.getElementById("liveauction");
    //         console.log(ele,"ele");
    //         if(ele)
    //         {
    //             if(ele != null && ele !="undefined")
    //             {
    //                 window.scrollTo(0,ele.offsetTop)


    //             }
    //         } 
    //         setIshome(false) 
    //     }
    //     else
    //     {
    //         alert(1)
    //         // state
    //         window.scrollTo(0,0)
    //     }

    //     // return () => state = null

    // },[state])

    // const pull_data = (data,click) => {
    //     console.log(data,click,"all data"); 
    //     LOGS DATA FROM CHILD (My name is Dean Winchester... &)
    //     var ele = document.getElementById(data);
    //     console.log(ele,"ele");
    //     if(click && data)
    //     {
    //         if(ele != null && ele !="undefined")
    //         {
    //             window.scrollTo(0,ele.offsetTop)


    //         }
    //     }



    //   }

    const collection = ([
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" }]
    )
    const slideone = {
        dots: false,
        infinite: true,
        smartSpeed: 4000,
        autoplayHoverPause: true,
        autoplay: true,
        // loop:true,
        nav: false,
        margin: 30,
        speed: 1500,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        },

    }

    const options1 = {
        loop: true,
        margin: 20,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        nav: true,
        dots: false,
        smartSpeed: 1200,
        autoplay: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    }
    const [blog, setBlog] = useState([])
    const [aboutlist1, Setaboutlist1] = useState([
        {
            "id": 1,
        },
        {
            "id": 2,
        },
        {
            "id": 3,
        },
        {
            "id": 4,
        },
        {
            "id": 5,
        },
        {
            "id": 6,
        },
        {
            "id": 7,
        },
        {
            "id": 8,
        },
    ])

    const slidetwo = {
        dots: false,
        infinite: true,
        smartSpeed: 3500,
        autoplayHoverPause: true,
        autoplay: true,
        loop: true,
        nav: false,
        margin: 30,
        speed: 1500,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        },
    }
    var renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
        if (completed) {

            return <span>Waiting for Owner To Accept</span>
        } else {
            return <span> <span className="hourds">{formatTime(days)} d</span>{" "}
                <span className="semicolan">:</span>{" "} <span className="hourds">{formatTime(hours)} h</span> <span className="semicolan">:</span> <span className="hourds">{formatTime(minutes)} m</span> <span className="semicolan">:</span> <span className="hourds">{formatTime(seconds)} s</span> </span>;
        }
    };
    const formatTime = (time) => {
        return String(time).padStart(2, '0')
    }

    /** lp */
    const { Categorys } = useSelector((state) => state.LoginReducer);

    const [creatorstatus, setCreator] = useState(false)
    const [TopCreator, SetTopCreator] = useState([])

    var LikeForwardRef = useRef();
    const [LikedTokenList, setLikedTokenList] = useState([]);


    const [homecontent, setHomecontent] = useState([]);

    const [homecontentmidd, setHomecontentmidd] = useState([])






    const [HotAuctionData, SetHotAuction] = useState({
        All: [],
        "tab": "All"
    });
    const [SaleData, SetSaleData] = useState({
        All: [],
    });
    const [category, setCategory] = useState("All")
    const [CreateCollectionState, SetCreateCollectionState] = useState({
        All: [],
        "tab": "All"
    });
    const [val, Setval] = useState("");
    const [Searchdata, SetSearch] = React.useState(null);
    const [filter, setFilter] = useState({ auction: "Recent", collection: "new", sale: "LatestDrops" });
    var [filterview, setFilterview] = useState({ auction: "Recently created", collection: "Recently created", sale: "Recently created" })
    var [promotedToken, setPromotedtoken] = useState({});

    // const [homecontent,setHomecontent]=useState([]);
    // const[homecontentmidd,setHomecontentmidd]=useState([])
    // console.log("------------------",category);

    useEffect(() => {
        Getandselldata();
        Getandsellnft();
        TopCreatorFunc()
        HotAuction(1, 'All')
        HotSales('All')
        // Collectionlist('All')
        CollectionByCreate('All')
        GetPromtion();
        getarticles()
    }, [])

    const CollectionByCreate = async (data) => {
        var SendDATA = {
            tab: data,
            limit: 4,
            ProfileUrl: "",
            page: 1,
            from: "home",
            filter: filter.collection,
        };
        let Resp = await CollectionByCreator(SendDATA)
        // console.log('fhgngfngf',Resp)
        SetCreateCollectionState({
            ...CreateCollectionState,
            ...{
                [data]: Resp?.data,
                "tab": data,
                "filter": filter.collection,
            },
        })
        setCategory(data)
    }

    const getarticles = async () => {
        var resp = await getarticle()
        if (resp?.status) {
            setBlog(resp?.data)
        }

    }
    const TopCreatorFunc = async () => {
        var resp = await TopCreatorApi()
        console.log('topcreator', resp)
        if (resp?.data.length > 8) {
            SetTopCreator(resp?.data.slice(0, 8))
            setCreator(true)
        }
        else {
            SetTopCreator(resp?.data)
            setCreator(true)
        }

    }
    // console.log("HotAuctionData",HotAuctionData)
    const HotAuction = async (data, tabs) => {
        var SendDATA = {
            TabName: tabs,
            limit: 8,
            ProfileUrl: "",
            page: 1,
            from: "Auction",
            filter: filter.auction,
        };
        let Resp = await Token_List_Func(SendDATA);
        if (Resp?.success == "success") {
            SetHotAuction({
                ...HotAuctionData,
                ...{
                    [tabs]: Resp?.data,
                    "tab": tabs,
                    "filter": filter.auction
                },
            })
            SetHotAuctionstate(false)
        }
        else {
            SetHotAuction({
                ...HotAuctionData,
                ...{
                    [tabs]: Resp?.data,
                    "tab": tabs,
                    "filter": filter.auction

                },
            })
            SetHotAuctionstate(false)
        }


    }
    const HotSales = async (tabs) => {
        var SendDATA = {
            TabName: tabs,
            limit: 8,
            ProfileUrl: "",
            page: 1,
            from: "Sale",
            filter: filter.sale
        };
        let Resp = await Token_List_Func(SendDATA);
        console.log('hot sales', Resp, 'tabs', tabs);
        if (Resp?.success == "success") {
            SetSaleData({
                ...SaleData,
                ...{
                    [tabs]: Resp?.data,
                    "tab": tabs,
                    "filter": filter.sale
                },
            })
            SetHotsalestate(false)
        }
        else {
            SetSaleData({
                ...SaleData,
                ...{
                    [tabs]: Resp?.data,
                    "tab": tabs,
                    "filter": filter.sale
                },
            })
            SetHotsalestate(false)
        }
    }


    function LikeList(data) {
        setLikedTokenList(data)
    }

    const Collectionlist = async (tabs) => {
        var SendDATA = {
            TabName: tabs,
            limit: 4,
            ProfileUrl: "",
            page: 1,
            from: "collection",
        };
        let Resp = await Token_List_Func(SendDATA);
        if (Resp?.success == "success") setCategory({
            ...SaleData,
            ...{
                [tabs]: Resp?.data,
                "tab": tabs
            },
        })
    }

    const Getandselldata = async () => {

        var resp = await getCmsContent("homepage_top");
        //  console.log("cmslist",resp.data)
        if (resp?.status)
            setHomecontent(resp.data);
    }
    const Getandsellnft = async () => {

        var resp = await getCmsContent("homepage_middle");
        console.log("cmslist", resp?.data)
        if (resp?.status)
            setHomecontentmidd(resp?.data);
    }

    const OnChange = async (value) => {
        // console.log("vallllllllllll",value);
        if (value) {
            Setval(value);
            var Resp = await SearchAction({
                keyword: value,
                limit: 3,
                page: 1,
                from: "home",
            });
            // console.log("response", Resp);
            if (Resp?.success === "success") {
                SetSearch(Resp);
            }
            else {
                SetSearch(null)
            }
        }
        else {
            SetSearch(null)
            Setval('')
        }
    };

    const GetPromtion = async () => {
        // var resp = await Getpromotedtoken()
        var protoken = await Getpromotedtoken();
        console.log('DGJFGJFJD', protoken)
        if (protoken?.success == 'success') {
            console.log(protoken?.data[0], "protoken")
            setPromotedtoken(protoken?.data[0])
            setBannerstatus(false)
        };
    }

    useEffect(() => {
        // if(filter?.auction)
        if (HotAuctionData.filter !== undefined && filter.auction !== HotAuctionData.filter) {
            HotAuction('hi', HotAuctionData.tab)
        }
        if (SaleData.filter !== undefined && filter.sale !== SaleData.filter) {
            HotSales(SaleData.tab)
        }
        if (CreateCollectionState.filter !== undefined && filter.collection !== CreateCollectionState.filter) {
            CollectionByCreate(CreateCollectionState.tab)
        }
    }, [filter])

    const AuctionFilter = (val, show) => {
        setFilter({ ...filter, ...{ auction: val } });
        setFilterview({ ...filterview, ...{ auction: show } });
    }

    const SaleFilter = (val, show) => {
        setFilter({ ...filter, ...{ sale: val } });
        setFilterview({ ...filterview, ...{ sale: show } });
    }

    const CollectionFilter = (val, show) => {
        setFilter({ ...filter, ...{ collection: val } });
        setFilterview({ ...filterview, ...{ collection: show } });
    }
    const midjourney = async (msg) => {
        try {

              

            
            var data = JSON.stringify({
              "msg": "msg",
              "ref": "",
              "webhookOverride": "",
              "ignorePrefilter": "false"
            });
            
            var config = {
              method: 'post',
              url: 'https://api.thenextleg.io/v2/imagine',
              headers: { 
                'Authorization': 'Bearer 014ad4ad-d99f-42a3-8539-d61da39d8d99', 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
             axios(config)
            .then(async function (response) {
              console.log("IMAGEMESSAGEID",JSON.stringify(response.data));




              if(response.data.success == true){
               var datas=await geturl(response.data.messageId)


               if(datas){
                console.log("imgurlllll",datas)
               }
              }


            })


            

        }
        catch (err) {
            console.log("ERRRRRRRR", err)
        }



    }



    const geturl=async (id)=>{
        var progress;
        var retdata;

             do{
                var resp = await createImg({id : id})
                progress=resp.data.progress
                console.log(progress,resp)
             }while(Number(progress) != 100)

             return retdata;

  

        
    }

    useEffect(() => {
        const preventContextMenu = (event) => {
            event.preventDefault();
        };

        const images = document.querySelectorAll('img');

        images.forEach((image) => {
            image.addEventListener('contextmenu', preventContextMenu);
        });

        return () => {
            images.forEach((image) => {
                image.removeEventListener('contextmenu', preventContextMenu);
            });
        };
    }, []);

    // export const nested = async(req,res)=>{
    //     var data = await Emp.aggregate([
    //       {$lookup:{
    //         from:"users",
    //         let:{"id":"$email"},
    //         pipeline:[{$match:{$expr:{$eq:["$email","$$id"]}}},
    //        {$lookup:{
    //         from:"emps",
    //         let:{"val":"$name"},
    //         pipeline:[{$match:{$expr:{$eq:["$name","$$val"]}}}],
    //         as:"result"
    //        }},
    //       ],
    //       as:"data"

    //       }}
    //     ])
    //    }

    // document.oncontextmenu = function () {
    //     return false;
    // }

    return (
        <>
            <div className="homepage" id="homescreen" oncontextmenu="return false;">
                <Header />
                <img src="" id="photo" />
                <div className="modals"><Modals /></div>
                <section className="tf-slider">
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-md-12">


                                <div className="swiper-container slider-home ">
                                    <div className="tf-slider-item style-4 top-banner">
                                        <div className="row align-items-center mt-5 w-100">
                                            <div className="col-md-6">
                                                {/* <div className="first-p" dangerouslySetInnerHTML={{ __html: homecontent?.answer }}></div> */}
                                                <div>
                                                    <p className="home_banne_tex">
                                                        <div className="first-p" dangerouslySetInnerHTML={{ __html: homecontent?.description }}></div></p>
                                                    <p className="home_banne_tex_dec"><div className="first-p" dangerouslySetInnerHTML={{ __html: homecontent?.answer }}></div></p>
                                                </div>
                                                <div className="btn-slider exploretop mt-5">
                                                    {/* <input type="button" onClick={() => midjourney("cat and dog fight")} value="Midjourney" /> */}
                                                    <NavLink to="/explore" className="tf-button style-2">
                                                        <img src={rocket} alt="images" className="img-fluid rocketleft" />

                                                        Explore
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div class="card firstsec mt-3">
                                                    {promotedToken?.CompressedFile ?
                                                        <Link to={`/info/${promotedToken?.CollectionNetwork}/${promotedToken?.ContractAddress}/${promotedToken?.NFTOwner}/${promotedToken?.NFTId}`}>
                                                            <ImgAudVideo
                                                                file={`${config.IMG_URL}/nft/${promotedToken?.NFTCreator}/Compressed/NFT/${promotedToken?.CompressedFile}`}
                                                                origFile={`${config.IMG_URL}/nft/${promotedToken?.NFTCreator}/Original/NFT/${promotedToken?.OriginalFile}`}
                                                                thumb={`${config.IMG_URL}/nft/${promotedToken.NFTCreator}/Compressed/NFT_THUMB/${promotedToken?.CompressedThumbFile}`}
                                                                type={
                                                                    promotedToken?.CompressedFile
                                                                        ? promotedToken?.CompressedFile?.includes(".webp")
                                                                            ? "image"
                                                                            : promotedToken?.CompressedFile.includes(".webm")
                                                                                ? "video"
                                                                                : "audio"
                                                                        : promotedToken?.CompressedFile
                                                                }
                                                            />
                                                            <div class="card-body">
                                                                <h4 class="card-title">Auction Ends In</h4>
                                                                <span className="js-countdown countdown style-3 countdown_value">
                                                                    <Countdown
                                                                        date={promotedToken?.EndClockTime ? promotedToken?.EndClockTime : Date.now() + 8000000}
                                                                        autoStart={true}
                                                                        renderer={renderer} />
                                                                </span>

                                                            </div>
                                                        </Link>
                                                        : <img src={placee} alt="d" classNameName="img-fluid" />}
                                                    {/* <div class="card-body">
                                                        <h4 class="card-title">Auction Ends In</h4>
                                                        <span className="js-countdown countdown style-3 countdown_value">
                                                            <Countdown
                                                                date={promotedToken?.EndClockTime ? promotedToken?.EndClockTime : Date.now() + 8000000}
                                                                autoStart={true}
                                                                renderer={renderer} />
                                                        </span> */}

                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>

                <section className="tf-section tf-top-seller">
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tf-heading style-3 mb40 wow fadeInUp" data-aos="fade-up">
                                    <h3 className="heading">Top Creators</h3>
                                    <p className="sub-heading">Checkout Top Rated Creators on the NFT Marketplace </p>
                                </div>
                            </div>
                            {creatorstatus == false ? <>
                                <div className="text-centre">
                                    <h3>Loading...</h3>
                                    {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
                                    {/* <p>Nothing for ReSale at this time</p> */}
                                    <div className="load-more">
                                        {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                                    </div>
                                </div>
                            </> : TopCreator?.length > 0 ?
                                TopCreator?.map((data, index) => {
                                    return (
                                        <div className="col-xl-3 col-lg-4 col-sm-6 col-md-6">

                                            <div className="card card1">
                                                <NavLink to={`/profile/${data?.CustomUrl}`} className="text-center"><img
                                                    src={
                                                        data?.Profile
                                                            ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                                            : config.profile
                                                    }
                                                    alt="Image" className="card-images"
                                                /> </NavLink>
                                                <div className="content text-center all1">
                                                    <div className="title">
                                                        {/* <NavLink to={"/my-item/" + data.CustomUrl}>  */}
                                                        <NavLink to={`/profile/${data?.CustomUrl}`}>
                                                            {data?.DisplayName
                                                                ? data?.DisplayName
                                                                : address_showing(
                                                                    data?.WalletAddress
                                                                )}
                                                        </NavLink>
                                                        {/* </NavLink> */}
                                                    </div>
                                                    <div className="price">
                                                        <span className="price-eth cardvalue">$-{data.value}</span>
                                                    </div>
                                                </div>
                                                {/* <img src={Tick} alt="Image" width="14" height="14" className="img-fluid tick"/> */}

                                            </div>


                                            {/* <div className="tf-author-wrap" data-aos="fade-up">
                                <span className="number">{index+1}.</span>
                                <div className="tf-author">
                                    <div className="image">
                                    <img
                                        src={
                                        data?.Profile
                                            ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                            : require("../assets/images/author-detail-3.png")
                                        }
                                        alt="Image" className="auttor"
                                    />
                                        <img src={Tick} alt="Image" width="14" height="14" className="img-fluid tick"/>
                                            
                                    </div>
                                    <div className="content">
                                        <div className="title">
                                            <NavLink to={"/my-item/"+data.CustomUrl}> {data?.DisplayName
                              ? data?.DisplayName
                              : address_showing(
                                  data?.WalletAddress
                                )}</NavLink>
                                        </div>
                                        <div className="price">
                                            <span className="price-eth">$-{data.value}</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                                        </div>
                                    )
                                })
                                :
                                <NoData />}
                        </div>
                    </div>
                </section>

                <section id="hotauctionscroll" className="tf-section tf-hot-auction tf-filter">
                    <div className="tf-container">
                        <div className="row ">
                            <div className="col-md-12">
                                <div className="tf-heading style-3 mb23 wow fadeInUp" data-aos="fade-up">
                                    <h3 className="heading">Hot Auction</h3>
                                    <p className="sub-heading">The Most Creative Creator - Based on the last 30 days </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="top-menu" data-aos="fade-up">
                                    <ul className="filter-menu">

                                        <li className={HotAuctionData?.tab == "All" ? "active" : ""} onClick={() => HotAuction(1, 'All')}><a>All</a></li>

                                        {Categorys.map(item => <li className={HotAuctionData.tab == item.label ? "active" : ""} onClick={() => HotAuction(item.value, item.label)}><a>{item.label}</a></li>)}
                                    </ul>
                                    <div id="item_category2" className="dropdown" >
                                        <Link to="#" className="btn-selector nolink allshow">{filterview.auction}</Link>
                                        <ul className="show allshow">
                                            <li onClick={() => { AuctionFilter('Recent', 'Recently created') }}><span>Recently created</span></li>
                                            <li onClick={() => { AuctionFilter('BLTH', 'Bid Low to High') }}><span>Bid Low to High</span></li>
                                            <li onClick={() => { AuctionFilter('BHTL', 'Bid High to Low') }}><span>Bid High to Low</span></li>
                                            <li onClick={() => { AuctionFilter('OLD', 'Oldest') }}><span>Oldest</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tf-filter-container row" data-aos="fade-up">
                            {hotactionstate == true ? <>
                                <div className="text-centre">
                                    <h3>Loading...</h3>
                                    {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
                                    {/* <p>Nothing for ReSale at this time</p> */}
                                    <div className="load-more">
                                        {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                                    </div>
                                </div>
                            </> : HotAuctionData[HotAuctionData.tab]?.length > 0 ? HotAuctionData[HotAuctionData.tab]?.slice(0, 8).map((item, index) =>
                                <div
                                    className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                                    data-aos="zoom-in-up"
                                >
                                    <Card
                                        product={item}
                                        type="marketplace"
                                        index={index}
                                        LikeList={LikeList}
                                        LikedTokenList={LikedTokenList}
                                        setLikedTokenList={setLikedTokenList}
                                        LikeForwardRef={LikeForwardRef}
                                    />
                                </div>
                            )
                                :
                                // <></>
                                <NoData />
                            }


                        </div>
                        {HotAuctionData[HotAuctionData.tab]?.length > 0 && <div className="col-md-12">
                            <div className="btn-loadmore wow fadeInUp" data-aos="fade-up">
                                <NavLink to="/explore" className="tf-button style-8 loadmore">Explore More
                                    {/* <i className="far fa-long-arrow-right"></i> */}
                                </NavLink>
                            </div>
                        </div>}
                    </div>
                </section>


                {/* <section className="tf-section  tf-filter">
                <div className="tf-container">
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="tf-heading style-3 mb28 wow fadeInUp"  data-aos="fade-up">
                                <h3 className="heading">Top Collection</h3>
                                <p className="sub-heading">The most well-known Collection - Based on the last 30 days  </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="top-menu wow fadeInUp" data-aos="fade-up">
                                <ul className="filter-menu">
                                 <li className={category == "All" ? "active" : ""} onClick={() => CollectionByCreate('All')}>All</li>

                                    {Categorys.map(item=>  <li className={category == item.label ? "active" : ""} onClick={() => CollectionByCreate(item.label)}>{item.label}</li>)}

                                    </ul>
                                  
                                <div id="item_category3" className="dropdown" style={show == true ? {zIndex:9999} : {zIndex:"auto"}}>
                                    <Link to="#" className="btn-selector nolink ">{filterview.collection}</Link>
                                    <ul className="show">
                                        <li onClick={()=>{CollectionFilter('new','Recently created')}}><span>Recently created</span></li>
                                        <li onClick={()=>{CollectionFilter('old','Oldest')}}><span>Oldest</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tf-filter-container row wow fadeInUp"  data-aos="fade-up">
                    
                    </div>
                    </div>
                    <section className="tf-section tf-top-collection tf-filter">
                        <div className="tf-container">
                    <div className="row">
                    {CreateCollectionState[CreateCollectionState.tab]?.length > 0 ?

                    <div className="col-md-12">
                            <div className="swiper-container collection-over">
                                <div className="swiper-wrapper">
                                <OwlCarousel className='owl-theme' {...slideone}>
                                {CreateCollectionState[CreateCollectionState.tab].map((item,index) => {
                                        return ( 
                                    <>
                                    {item?.CollectionCount >= 3 &&
                                    <div className="swiper-slide">                               
                                        <div className="slider-item">
                                            <div className="sc-product style1 collection collection2" >
                                                <div className="top">
                                                    <div className="content">
                                                        <Link to ={"/collection/"+item.CollectionSymbol}>
                                                        <div className="author-cl">
                                                            <img src={item.CollectionProfileImage ? `${config.IMG_URL}/collection/${item.CollectionSymbol}/${item.CollectionProfileImage}` : require("../assets/images/add-collection.jpg")} alt="images" />
                                                        </div>
                                                        </Link>
                                                        <div className="inner">
                                                            <Link to ={"/collection/"+item.CollectionSymbol} className="name">{item.CollectionName}</Link>
                                                            <div className="create">created by <Link to={"/profile/"+item.CustomUrl}>{item.DisplayName ? item.DisplayName : address_showing(item.CollectionCreator)}</Link></div>
                                                        </div>
                                                    </div>
                                                    <div className="wish-list">
                                                        <Link to="#" className="heart-icon"></Link>
                                                    </div>
                                                </div>
                                     
                                                    <div className="thumb-collection">
                                                       {item?.Tokens[0]&&
                                                        <div className="left-thumb">
                                                            <Link to={item?.Tokens[0]?.Link}>                                                                                                                        
                                                           <ImgAudVideo
                                                                classname="images"
                                                                file={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Compressed/NFT/${item?.Tokens[0]?.CompressedFile}`}
                                                                origFile={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Original/NFT/${item?.Tokens[0]?.NFTOrginalImage}`}
                                                                type={
                                                                item?.Tokens[0]?.CompressedFile
                                                                    ? item?.Tokens[0]?.CompressedFile?.includes(".webp")
                                                                    ? "image"
                                                                    : item?.Tokens[0]?.CompressedFile.includes(".webm")
                                                                    ? "video"
                                                                    : "audio"
                                                                    : item?.Tokens[0]?.CompressedFile
                                                                }
                                                           />
                                                           </Link>
                                                        </div>}
                                                        {item?.Tokens[1]&&
                                                        <div className="right-thumb">
                                                            {item?.Tokens[1]
                                                            &&<div className="top-cl">
                                                            <Link to={item?.Tokens[1]?.Link}>
                                                            <ImgAudVideo
                                                                classname="images"
                                                                file={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Compressed/NFT/${item?.Tokens[1]?.CompressedFile}`}
                                                                origFile={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Original/NFT/${item?.Tokens[1]?.NFTOrginalImage}`}
                                                                type={
                                                                item?.Tokens[1]?.CompressedFile
                                                                    ? item?.Tokens[1]?.CompressedFile?.includes(".webp")
                                                                    ? "image"
                                                                    : item?.Tokens[1]?.CompressedFile.includes(".webm")
                                                                    ? "video"
                                                                    : "audio"
                                                                    : item?.Tokens[1]?.CompressedFile
                                                                }
                                                           />
                                                           </Link>
                                                            </div>}
                                                            {item?.Tokens[2]
                                                            &&<div className="bottom-cl">
                                                            <Link to={item?.Tokens[2]?.Link}>
                                                            <ImgAudVideo
                                                                classname="images"
                                                                file={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Compressed/NFT/${item?.Tokens[2]?.CompressedFile}`}
                                                                origFile={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Original/NFT/${item?.Tokens[2]?.NFTOrginalImage}`}
                                                                type={
                                                                item?.Tokens[2]?.CompressedFile
                                                                    ? item?.Tokens[2]?.CompressedFile?.includes(".webp")
                                                                    ? "image"
                                                                    : item?.Tokens[2]?.CompressedFile.includes(".webm")
                                                                    ? "video"
                                                                    : "audio"
                                                                    : item?.Tokens[2]?.CompressedFile
                                                                }
                                                           />
                                                           </Link>
                                                            </div>}
                                                        </div>
                                                        }
                                                    </div>
                                    
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    </>
                                    )})}
                                </OwlCarousel>   
                                </div>
                            </div>
                        </div>
                        :    <NoData/>
                    }
                    {CreateCollectionState[CreateCollectionState.tab]?.length > 0 && 
                    <div className="col-md-12">
                        <div className="btn-loadmore wow fadeInUp" data-aos="fade-up">
                            <NavLink to="/collectionlist" className="tf-button style-8 loadmore">Explore More 
                            </NavLink>
                        </div>
                    </div>}
                    </div>
                    </div>
                    </section>
            </section> */}


                <section className="tf-section tf-banner-create" >
                    <div className="tf-container">
                        <div className="row vertical-middle align-items-center mt-5">
                            <div className="col-md-6" data-aos="zoom-in">
                                <div className="content-banner">
                                    {/* <div className="company ">
                                    <p id="logo_header"></p>
                                </div> */}
                                    {/* <h2 className="wow fadeInUp" data-aos="fade-up">AI Image Generator</h2> */}
                                    {/* <p className="wow fadeInUp"  data-aos="fade-up">Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel</p> */}
                                    <h2 className="wow fadeInUp" data-aos="fade-up"><div className="wow fadeInUp" data-aos="fade-up" dangerouslySetInnerHTML={{ __html: homecontentmidd?.description }}></div></h2>

                                    {/* <div className="subheads" dangerouslySetInnerHTML={{ __html: homecontentmidd?.answer }}></div> */}
                                    <p >
                                        <div className="home_banne_tex_dec" dangerouslySetInnerHTML={{ __html: homecontentmidd?.answer }}></div>
                                    </p>


                                    <div className="group-btn wow fadeInUp d-flex mt-5" data-aos="fade-up">
                                        <NavLink to="/create"
                                            state={{ generate: true }}
                                            className="tf-button style-2 btn-1">Generate</NavLink>
                                        {/* <NavLink to="/" className="tf-button style-3 btn-2">Buy Item</NavLink> */}
                                    </div>
                                    <div className="shape ani4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="176" height="143" viewBox="0 0 176 143" fill="none">
                                            <path d="M77.3294 126.162C55.2085 124.134 9.25963 110.354 2.43137 71.4571C0.743732 62.3166 4.23102 40.7095 31.6813 27.4047" stroke="#FF008E" stroke-width="3" />
                                            <path d="M98.7243 15.9962C120.845 18.0242 166.794 31.8045 173.622 70.7011C175.31 79.8416 171.823 101.449 144.372 114.753" stroke="#E33B3B" stroke-width="3" />
                                            <path d="M42.074 15.4582L50.5959 0.701185C51.713 2.61761 58.4014 15.2279 58.6785 15.7033C59.5164 17.1407 60.6862 22.5834 57.0882 26.4461C53.5967 30.1945 48.4203 31.7013 43.6282 26.5641C39.9052 22.573 40.9932 17.5533 42.074 15.4582Z" fill="#FF008E" />
                                            <path d="M110.5 101.492L113.805 116.179L123.24 121.734L113.645 127.009L109.908 141.592L106.604 126.905L97.1689 121.35L106.763 116.075L110.5 101.492Z" fill="#E33B3B" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" data-aos="zoom-in">

                                <div className="text-center position-relative mt-3">
                                    <img src={wing1} className="img-fluid wing1" alt="hh" />
                                    <img src={wing2} className="img-fluid wing2" alt="hh" />
                                    <div className="thumb sellcard">
                                        {/* {!promotedToken?.CompressedFile ?
                                            <Link to={`/info/${promotedToken?.CollectionNetwork}/${promotedToken?.ContractAddress}/${promotedToken?.NFTOwner}/${promotedToken?.NFTId}`}>
                                            <Link to="/">
                                                <ImgAudVideo
                                                    file={`${config.IMG_URL}/nft/${promotedToken?.NFTCreator}/Compressed/NFT/${promotedToken?.CompressedFile}`}
                                                    origFile={`${config.IMG_URL}/nft/${promotedToken?.NFTCreator}/Original/NFT/${promotedToken?.OriginalFile}`}
                                                    thumb={`${config.IMG_URL}/nft/${promotedToken.NFTCreator}/Compressed/NFT_THUMB/${promotedToken?.CompressedThumbFile}`}
                                                    type={
                                                        promotedToken?.CompressedFile
                                                            ? promotedToken?.CompressedFile?.includes(".webp")
                                                                ? "image"
                                                                : promotedToken?.CompressedFile.includes(".webm")
                                                                    ? "video"
                                                                    : "audio"
                                                            : promotedToken?.CompressedFile
                                                    }
                                                />
                                                </Link> 
                                                :  */}
                                        <img src={`${config.IMG_URL}/cmsimg/${homecontentmidd?.img}`} alt="images" classNameName="img-fluid" />
                                        {/* } */}
                                    </div>
                                </div>


                                {/* <div className="thumb-banner">
                                    <div className="shape1 ani4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="255" viewBox="0 0 20 255" fill="none">
                                            <path d="M1.04052 17.3472L10.0151 0C11.2529 2.21454 18.6826 16.7979 18.9896 17.3472C19.9181 19.0082 21.2692 25.3321 17.4423 29.8963C13.7287 34.3254 8.15831 36.1708 2.89733 30.2654C-1.18999 25.6774 -0.0941967 19.8078 1.04052 17.3472Z" fill="#FF008E" />
                                            <circle r="3" transform="matrix(1 0 0 -1 9.5 227.5)" fill="#FF008E" stroke="#FF008E" />
                                            <circle r="3" transform="matrix(1 0 0 -1 9.5 251.5)" fill="#FF008E" stroke="#FF008E" />
                                            <line y1="-1" x2="152" y2="-1" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 9 205)" stroke="#FF008E" stroke-width="2" />
                                        </svg>
                                    </div>
                                    <div className="thumb">
                                        {promotedToken?.CompressedFile ?
                                            <Link to={`/info/${promotedToken?.CollectionNetwork}/${promotedToken?.ContractAddress}/${promotedToken?.NFTOwner}/${promotedToken?.NFTId}`}>
                                                <ImgAudVideo
                                                    file={`${config.IMG_URL}/nft/${promotedToken?.NFTCreator}/Compressed/NFT/${promotedToken?.CompressedFile}`}
                                                    origFile={`${config.IMG_URL}/nft/${promotedToken?.NFTCreator}/Original/NFT/${promotedToken?.OriginalFile}`}
                                                    thumb={`${config.IMG_URL}/nft/${promotedToken.NFTCreator}/Compressed/NFT_THUMB/${promotedToken?.CompressedThumbFile}`}
                                                    type={
                                                        promotedToken?.CompressedFile
                                                            ? promotedToken?.CompressedFile?.includes(".webp")
                                                                ? "image"
                                                                : promotedToken?.CompressedFile.includes(".webm")
                                                                    ? "video"
                                                                    : "audio"
                                                            : promotedToken?.CompressedFile
                                                    }
                                                /></Link> : <img src={require("../assets/images/banner-new.jpg")} alt="images" classNameName="img-fluid" />}
                                    </div>
                                    <div className="shape2 ani5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="272" viewBox="0 0 48 272" fill="none">
                                            <line x1="24" y1="44" x2="24" y2="196" stroke="#E33B3B" stroke-width="2" />
                                            <path d="M24 209L30.4822 231.992L48 240.5L30.4822 249.008L24 272L17.5178 249.008L0 240.5L17.5178 231.992L24 209Z" fill="#E33B3B" />
                                            <circle cx="23.5" cy="27.5" r="3.5" fill="#E33B3B" />
                                            <circle cx="23.5" cy="3.5" r="3.5" fill="#E33B3B" />
                                        </svg>
                                    </div>
                                </div> */}
                            </div>

                        </div>
                    </div>
                </section>

                <section className="tf-section tf-hot-pick tf-filter">
                    <div className="tf-container">
                        <div className="row ">
                            <div className="col-md-12">
                                <div className="tf-heading style-3 mb26 wow fadeInUp" data-aos="fade-up">
                                    <h3 className="heading">Hot Sales</h3>
                                    <p className="sub-heading">The Most creative Creator - Based on the last 30 Days </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="top-menu wow fadeInUp" data-aos="fade-up">
                                    <ul className="filter-menu">
                                        <li className={SaleData.tab == "All" ? "active" : ""} onClick={() => HotSales('All')}><a>All</a></li>
                                        {Categorys.map(item => <li className={SaleData.tab == item.label ? "active" : ""} onClick={() => HotSales(item.label)}><a>{item.label}</a></li>)}
                                    </ul>
                                    <div id="item_category2" className="dropdown">
                                        <NavLink className="btn-selector nolink  allshow">{filterview.sale}</NavLink>
                                        <ul className="show allshow">
                                            <li onClick={() => { SaleFilter('BLTH', "Price Low to High") }}><span>Price Low to High</span></li>
                                            <li onClick={() => { SaleFilter('BHTL', "Price High to Low") }}><span>Price High to Low</span></li>
                                            <li onClick={() => { SaleFilter('OLD', "Oldest") }}><span>Oldest</span></li>
                                            <li onClick={() => { SaleFilter('LatestDrops', "Recently Created") }}><span>Recently Created</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tf-filter-container row wow fadeInUp" data-aos="fade-up">
                            {
                                hotsalestate == true ? <>
                                    <div className="text-centre">
                                        <h3>Loading...</h3>
                                        {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
                                        {/* <p>Nothing for ReSale at this time</p> */}
                                        <div className="load-more">
                                            {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                                        </div>
                                    </div>
                                </> : SaleData[SaleData.tab]?.length > 0 ? SaleData[SaleData.tab]?.slice(0, 8).map((item, index) =>
                                    <div
                                        className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                                        data-aos="zoom-in-up"
                                    >
                                        <Card
                                            product={item}
                                            type="marketplace"
                                            index={index}
                                            LikeList={LikeList}
                                            LikedTokenList={LikedTokenList}
                                            setLikedTokenList={setLikedTokenList}
                                            LikeForwardRef={LikeForwardRef}
                                        />
                                    </div>
                                )
                                    : <NoData />
                            }
                        </div>
                        {SaleData[SaleData.tab]?.length > 0 && <div className="col-md-12">
                            <div className="btn-loadmore wow fadeInUp" data-aos="fade-up">
                                <NavLink to="/explore" className="tf-button style-8 loadmore">Explore More
                                    {/* <i className="far fa-long-arrow-right"></i> */}
                                </NavLink>
                            </div>
                        </div>}
                    </div>
                </section>

                <section className="tf-section tf-blog-pick tf-filter">
                    <div className="tf-container">
                        <div className="row ">
                            <div className="col-md-12">
                                <div className="tf-heading style-3 mb26 wow fadeInUp" data-aos="fade-up">
                                    <h3 className="heading">Blogs</h3>
                                    <p className="sub-heading">Lorem ipsum dolor sit amet consectetur. Diam faucibus mauris imperdiet a cras pharetra.</p>
                                </div>
                            </div>
                        </div>
                        <section className="aboutcarousel aboutblog pt-0 pb-5">
                            <div className="fullwidthcarousel1">
                                <OwlCarousel className='owl-theme' {...options1}>
                                    {blog.map((e, i) =>
                                        <div class='item'>
                                            <Link to="/blogdetail" state={{ item: e }}>
                                                <div class="card mt-3">
                                                    <img class="card-img-top" src={`${config.IMG_URL}/${e.img}`} alt="Card image cap" />
                                                    <div class="card-body">
                                                        <h5 class="card-title" >{e.heading}</h5>
                                                        <p className="grays mb-4">Published By {e.date && `${new Date(e?.date).toLocaleString('default', { month: 'long' })} ${new Date(e?.date).getDate()},${new Date(e?.date).getFullYear()}`}</p>
                                                        <p><div className="contetn_forn_ad_car"
                                                            dangerouslySetInnerHTML={{ __html: e?.content?.length > 100 ? e?.content.slice(0, 200) : e?.content }} /></p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )}
                                </OwlCarousel>
                            </div>
                            <div className="btn-loadmore mt-5 wow fadeInUp">
                                <NavLink to="/blog" className="tf-button style-8 loadmore">Learn More
                                    {/* <i className="far fa-long-arrow-right"></i> */}
                                </NavLink>
                            </div>
                        </section>


                    </div>
                </section>




                <Footer />
            </div>
        </>
    )
}