import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import action_config from './config/config';
import { isEmpty } from "../actions/common";
import { userRegister } from "../actions/axioss/user.axios";
import { event } from "jquery";

export default function EditProfile(props) {
    console.log("valData", props.valData);
    const navigate = useNavigate();
    const { token, payload, isAdmin } = useSelector(state => state.LoginReducer.User)
    const { Admin_address} = useSelector(state => state.LoginReducer)
    const initialValue = {
        DisplayName: payload?.DisplayName ?? "",
        EmailId: payload?.EmailId ?? "",
        Password: '',
        ConfirmPassword: '',
        Youtube: payload?.Youtube ?? "",
        Facebook: payload?.Facebook ?? "",
        Twitter: payload?.Twitter ?? "",
        Instagram: payload?.Instagram ?? "",
        Profile: payload?.Profile ?? "",
        WalletAddress: props?.valData?.WalletAddress ? props.valData?.WalletAddress : payload?.WalletAddress ?? "",
        // Profile:payload?.Profile ? action_config.BACK_URL + '/user/' + payload?.ProfileUrl + '/profile/' + payload?.Profile : '',
        Cover: payload?.Cover ?? "",
        Bio: payload?.Bio ?? "",
        CustomUrl: payload?.CustomUrl ?? "",
        Type: 'profile'
    }
    // console.log('asgdgdsg',payload)
    const dispatch = useDispatch()
    const [formValue, setFormValue] = useState(initialValue)
    const [ValidateError, SetValidateError] = useState('')
    const [onchangeimg, setOnchangeimg] = useState('')
    const [imageVal, setImageVal] = useState('')
    const [oldcostomurl, setOldcustomurl] = useState('')
    const [disablebtn, setDisablebtn] = useState(0)
    const {
        DisplayName,
        WalletAddress,
        EmailId,
        Password,
        ConfirmPassword,
        Youtube,
        Facebook,
        Twitter,
        Instagram,
        Profile,
        Cover,
        Bio,
        CustomUrl,
        Type
    } = formValue

    useEffect(() => {
        window.scrollTo(0, 0);
        getProfile()

    }, []);

    const getProfile = async () => {
        if (payload?.Profile) {
            // console.log('shdsh',payload)
            var profileimage = action_config.IMG_URL + '/user/' + payload.CustomUrl + '/profile/' + payload.Profile;
            setOnchangeimg(profileimage);
        }

    }
    const onChange = (e) => {
        setDisablebtn(0)
        const { value, id } = e.target
        if (id == 'CustomUrl'){
        setOldcustomurl(formValue.CustomUrl);
            var formData = { ...formValue, ...{ ['CustomUrl']: value.toString().replace(/\s/g, '').toLowerCase(),"CUS": true } }
        }else
            var formData = { ...formValue, ...{ [id]: value } }
        setFormValue(formData)
    }
    const Validation = useCallback((data) => {
        //console("validation Checking", data)
        let ValidateError = {}
        let imageSize = 5000000;

        //    if (onchangeimg !== "") {
        //        if (data.Profile !== "") {
        //            if (imageSize < (data.Profile).size) {
        //                ValidateError.Profile = "File size must be below 5mb"
        //            }
        //            if (!(/\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$/i).test((data.Profile).name)) {
        //                ValidateError.Profile = "file is invalid. only allowed JPG,PNG,WEBP,gif";
        //            }
        //        }

        //    }
        if (!data.DisplayName) ValidateError.DisplayName = 'DisplayName Required'
        if (!data.CustomUrl) ValidateError.CustomUrl = 'CustomUrl Required'
        // if (data.CustomUrl && !(action_config.notSpecil).test(data.CustomUrl)) ValidateError.CustomUrl = 'CustomUrl Must Be Allowed Only Letters and Numbers'

        if (!data.EmailId) ValidateError.EmailId = 'EmailId Required'
        if (data.EmailId && !(action_config.EMAIL).test(data.EmailId)) ValidateError.EmailId = 'Invalid Email Format'
        SetValidateError(ValidateError)
        return ValidateError
    }, [ValidateError])

    const FormSubmit = async () => {
        const id = toast.loading("Updating Progress..")
        let error = Validation(formValue)
        console.log('errsubbb', formValue, error, !isEmpty(error))
        if (!isEmpty(error)) {
            setDisablebtn(1)
            SetValidateError(error)
            toast.update(id, { render: 'Validation Failed', type: 'error', isLoading: false, autoClose: 1000, position: 'top-center', closeButton: true, closeOnClick: true })

        }
        else {

            let Resp = await userRegister(formValue)
            console.log("Checking the Validation", Resp);
            if (Resp.success === 'success') {
                let form=formValue;
                form.CUS=false
                setFormValue(form)
                toast.update(id, { render: 'Saved successfully', type: 'suceess', isLoading: false, autoClose: 1000, position: 'top-center', closeButton: true, closeOnClick: true })
                setDisablebtn(0)

                // GetProfile();


                dispatch({
                    type: 'Register_Section',
                    Register_Section: {
                        User: {
                            payload: Resp.data,
                            isAdmin: Resp.data?.isAdmin ? Resp.data?.isAdmin == 'Yes' ? true : false : isAdmin,
                            token: Resp.token ? Resp.token : token
                        }
                    }
                })

                dispatch({
                    type: 'Admin_address',
                    Admin_address: {
                        Admin_address : !Admin_address
                    }
                })

                if (Resp.data.CustomUrl) document.cookie = 'user' + "=" + Resp.data.CustomUrl + ";" + ";path=/";

                setTimeout(() => {
                    navigate(`/profile/${Resp.data.CustomUrl}`)
                }, 1000);


            }
            else {
                let form=formValue;
                form.CUS=false
                setFormValue(form)
                setDisablebtn(1)
                toast.update(id, { render: Resp?.msg ? Resp?.msg : 'Validation Failed', type: 'error', isLoading: false, autoClose: 1000, position: 'top-center', closeButton: true, closeOnClick: true })
                SetValidateError({ ...ValidateError, ...Resp.validate })
            }
        }

    }
    console.log("afawsfr", formValue);
    return (
        <>
            <div className="inner-content profile">
                <h3 className="profilename">{DisplayName}</h3>
                <h4 className="title-dashboard" data-aos="fade-right">Edit Profile</h4>
                <form className="form-edit-profile">
                    <div className="user-profile">
                        <div className="title">User details</div>
                        {/* <fieldset>
                                                <h6>Profile</h6>
                                                <input type="file" id="Profile" onChange={(e) => handleFile(e)} required />
                                                {ValidateError && ValidateError.Profile && <span className="error_msg">{ValidateError.Profile}</span>}
                                            </fieldset> */}
                        {/* <fieldset>
                                                <h6>Cover Image</h6>
                                                <input type="file" id="Cover" onChange={(e) => CoverImg(e)} required />
                                            </fieldset> */}
                        {/* {onchangeimg === '' &&
                                        <img src={require("../assets/images/edit-img.png")} alt="The Redemption" className="img" />
                                    } */}
                        <fieldset>
                            <h6>Full Name<span className="required">*</span></h6>
                            <input type="text" id="DisplayName" value={DisplayName} placeholder="Hernes" onChange={onChange} required autoComplete="off" />
                            {ValidateError && ValidateError.DisplayName && <span className="error_msg">{ValidateError.DisplayName}</span>}
                        </fieldset>
                        <fieldset>

                            <h6>Custom Url<span className="required">*</span></h6>
                            <input type="text" id="CustomUrl" value={CustomUrl} placeholder="hernes" onChange={onChange} required autoComplete="off" />

                            {ValidateError && ValidateError.CustomUrl && <span className="error_msg">{ValidateError.CustomUrl}</span>}
                        </fieldset>
                    </div>
                    <div className="user-profile">
                        <div className="title">Contact details</div>
                        <fieldset>
                            <h6>Email Address<span className="required">*</span></h6>
                            <input id="EmailId" type="text" value={EmailId} placeholder="hernes@gmail.com" onChange={onChange} required autoComplete="off" />
                            {ValidateError && ValidateError.EmailId && <span className="error_msg">{ValidateError.EmailId}</span>}
                        </fieldset>
                        <fieldset>
                            <h6>Bio</h6>
                            <input id="Bio" type="text" value={Bio} placeholder="Write Something about yourself" onChange={onChange} autoComplete="off" />
                        </fieldset>
                        <fieldset>
                            <h6>Youtube Link</h6>
                            <input id="Youtube" value={Youtube} type="text" placeholder="Youtube Address" onChange={onChange} autoComplete="off" />
                        </fieldset>
                        <fieldset>
                            <h6>Twitter Link</h6>
                            <input id="Twitter" value={Twitter} type="text" placeholder="Twitter Address" onChange={onChange} autoComplete="off" />
                        </fieldset>
                        <fieldset>
                            <h6>Facebook Link</h6>
                            <input id="Facebook" value={Facebook} type="text" placeholder="Facebook Address" onChange={onChange} autoComplete="off" />
                        </fieldset>
                        <fieldset>
                            <h6>Instagram Link</h6>
                            <input id="Instagram" value={Instagram} type="text" placeholder="Instagram Address" onChange={onChange} autoComplete="off" />
                        </fieldset>
                        {props.valData && <fieldset>
                            <h6>Refferal Code</h6>
                            <input id="refer_code" type="text" placeholder="Instagram Address" onChange={onChange} autoComplete="off" />
                        </fieldset>}
                    </div>
                    <div className="text-center">
                        <button className="btn-form" onClick={FormSubmit} type="button">
                            Update Settings
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}